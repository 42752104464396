<template>
  <TModal
    title="Import excel"
    size="lg"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButton
        icon="cil-check"
        content="Confirm"
        @click="confirm"
        :disabled="!items.length || confirmLoading"
      />
    </template>
    <TInputFile :value.sync="file" accept=".xlsx" :multiple="false" />
    <TButton
      content="Import"
      icon="cil-plus"
      @click="importData"
      :disabled="!file.length || loading"
      class="my-2 ml-auto d-flex"
    />
    <TTableSimple :fields="itemFields" :items="items" :loading="loading">
      <template #_="{ index }">
        <td>
          <TButtonRemove @click="removeItem(index)" />
        </td>
      </template>
      <template #customer="{ item }">
        <td :class="{ 'text-danger': item.is_error }">
          <TMessageText
            editable
            :value="item.customer_id"
            @change="item.customer_id = $event"
          />
        </td>
      </template>
      <template #order_id="{ item }">
        <td :class="{ 'text-danger': item.is_error }">
          <TMessageText
            editable
            :value="item.order_id"
            @change="item.order_id = $event"
          />
        </td>
      </template>
      <template #shipping_code="{ item }">
        <td :class="{ 'text-danger': item.is_error }">
          <TMessageText
            editable
            :value="item.shipping_code"
            @change="item.shipping_code = $event"
          />
        </td>
      </template>
      <template #box_id="{ item }">
        <td :class="{ 'text-danger': item.is_error }">
          <TMessageText
            editable
            :value="item.box_id"
            @change="item.box_id = $event"
          />
        </td>
      </template>
      <template #quantity="{ item }">
        <td :class="{ 'text-danger': item.is_error }">
          <TMessageNumber
            editable
            :value="item.quantity ? item.quantity : 0"
            @change="item.quantity = $event"
          />
        </td>
      </template>
    </TTableSimple>
    <TButton
      content="Remove all"
      icon="cil-x-circle"
      color="danger"
      @click="items = []"
      v-if="items.length"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    goods_delivery_id: {
      type: [String, Number],
      required: false,
    },
    mode: {
      type: String,
      default: "create-multiple",
    },
  },
  data() {
    return {
      file: [],
      items: [],
      createMultipleFields: [
        {
          key: "customer",
          label: "Customer",
        },
        {
          key: "order_id",
          label: "Order",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
      ],
      shippingCodeFields: [
        // {
        //   key: "order_id",
        //   label: "Order",
        // },
        {
          key: "shipping_code",
          label: "Shipping code",
        },
      ],
      loading: false,
      confirmLoading: false,
    };
  },
  computed: {
    itemFields() {
      const defaultFields = [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
        },
        {
          key: "box_id",
          label: "SKU Id",
        },
      ];
      return this.mode == "create-multiple"
        ? [...defaultFields, ...this.createMultipleFields]
        : [...defaultFields, ...this.shippingCodeFields];
    },
  },
  methods: {
    importData() {
      const type =
        this.mode == "create-multiple"
          ? "importGoodsDelivery"
          : "importShippingCode";
      this.loading = true;
      this.$tomoni.helper.converts[type]({
        goods_delivery_boxs: this.file[0]?.file,
      })
        .then(({ data }) => {
          this.items = data
            .filter((x) => x.box_id)
            .map((i) => {
              return {
                ...i,
                box_id: i?.box_id.toString(),
                shipping_code: i?.shipping_code?.toString(),
              };
            });
          this.file = [];
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    confirm() {
      const type =
        this.mode == "create-multiple"
          ? "createMultiple"
          : "updateShippingCode";
      this.confirmLoading = true;
      let data = {
        goods_delivery_id: this.goods_delivery_id,
        goods_delivery_boxs: this.items.map((i) =>
          this.lodash.pickBy(
            this.lodash.omit(i, "is_error"),
            this.lodash.identity
          )
        ),
      };
      this.$tomoni.warehouse.goods_delivery_boxes[type](data)
        .then(({ data }) => {
          if (this.mode == "create-multiple") {
            this.checkError(data);
          } else {
            this.checkError(data.fail_boxs);
          }
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    checkError(list) {
      if (!list.length) {
        this.items = [];
        this.$emit("updated");
        this.$emit("update:show", false);
      }
      if (list.length) {
        list.forEach((x) => {
          const itemErrorIndex = this.items.findIndex((i) => {
            return this.mode == "create-multiple"
              ? i.box_id == x.box_id && i.order_id == x.order_id
              : i.box_id == x.box_id && i.shipping_code == x.shipping_code;
          });
          if (itemErrorIndex >= 0) {
            this.$set(this.items[itemErrorIndex], "is_error", true);
          }
        });
      }
      if (this.items.length > list.length) {
        this.$emit("updated");
      }
    },
  },
};
</script>
