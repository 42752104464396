<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem :to="`?action=new_goods_delivery`" color="success">
            <TMessage content="New goods delivery" bold alignment="center">
              <template #prefix>
                <CIcon name="cil-plus" class="mr-2" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem>
            <FilterForGoodsDelivery :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(goods_delivery, index) in goods_deliveries"
            :key="`${index}${goods_delivery.id}`"
            :color="
              goods_delivery_id_selected == goods_delivery.id
                ? 'primary'
                : 'secondary'
            "
            :to="
              _.getAppendRouteQuery($route, {
                goods_delivery_id: goods_delivery.id,
              })
            "
          >
            <SCardGoodsDelivery :goods_delivery="goods_delivery" />
          </CListGroupItem>
          <CListGroupItem v-if="!goods_deliveries.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.goods_deliveries" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="goods_delivery_id_selected">
        <!-- Goods delivery overview -->
        <CCol col="12">
          <CCard>
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <TMessage content="Goods delivery" bold>
                <template #suffix>
                  {{ goods_delivery_id_selected }}
                </template>
              </TMessage>
              <SMessageDeliveryStatus
                class="ml-2"
                :id="goods_delivery_selected.status_id"
              />
              <TSpinner :loading="goods_delivery_loading" />
              <div class="d-flex ml-auto">
                <SButtonDeliveryStatus
                  v-for="status in goods_delivery_selected.steps_back"
                  :key="status"
                  :id="status"
                  @click="updateDelivery('previous_status', status)"
                  :disabled="goods_delivery_loading"
                />
                <CIcon
                  v-if="!lodash.isEmpty(goods_delivery_selected.steps_back)"
                  name="cil-action-undo"
                  class="mx-2"
                  style="transform: rotate(90deg)"
                />
                <SButtonDeliveryStatus
                  v-for="status in goods_delivery_selected.steps_forward"
                  :key="status"
                  :id="status"
                  @click="updateDelivery('next_status', status)"
                  :disabled="goods_delivery_loading"
                />
                <span
                  class="mx-2"
                  v-if="
                    !lodash.isEmpty(goods_delivery_selected.steps_back) ||
                    !lodash.isEmpty(goods_delivery_selected.steps_forward)
                  "
                  >|</span
                >
                <TButton
                  content="Export excel"
                  icon="cid-cloud-download"
                  size="sm"
                  @click="exportExcel"
                  :disabled="export_loading"
                />
                <TButtonDelete
                  v-if="!goods_delivery_boxes.length"
                  @click="removeGD"
                  class="ml-1 my-auto"
                  variant=""
                  :options="{ disabled: goods_delivery_loading }"
                />
                <TButtonRefresh
                  class="ml-1"
                  @click="refetchGoodsDelivery"
                  :options="{ disabled: goods_delivery_loading }"
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol col="7">
                  <TTableAsForm
                    :fields="goodsDelivertFields"
                    :data="goods_delivery_selected"
                  >
                    <template #delivery_partner="{ value }">
                      <SMessageDeliveryPartner
                        :id="
                          goods_delivery_selected.delivery_partner
                            ? goods_delivery_selected.partner_id
                            : ''
                        "
                        :content="
                          goods_delivery_selected.delivery_partner
                            ? value.name
                            : ''
                        "
                        :editable="deliveryEditable"
                        @change="updateDelivery('partner_id', $event)"
                      />
                    </template>
                    <template #shipping_cost="{ value }">
                      <TMessageMoney
                        :amount="value"
                        :editable="deliveryEditable"
                        :currency="goods_delivery_selected.currency_id"
                        @change="updateDelivery('shipping_cost', $event)"
                      />
                    </template>
                    <template #consignee>
                      <TMessageText
                        v-if="goods_delivery_selected.place_of_delivery"
                        :value="
                          goods_delivery_selected.place_of_delivery.consignee
                        "
                      />
                    </template>
                    <template #place_of_delivery="{ value }">
                      <SMessagePlaceOfDelivery
                        v-if="value"
                        :content="value.full_address"
                        :id="goods_delivery_selected.place_of_delivery_id"
                        :editable="deliveryEditable"
                        @change="updateDelivery('place_of_delivery_id', $event)"
                      />
                    </template>
                    <template #exported_date="{ value }">
                      <TMessageDateTime
                        v-if="value"
                        dateOnly
                        :content="value"
                      />
                    </template>
                    <template #created_at="{ value }">
                      <TMessageDateTime
                        v-if="value"
                        :content="value"
                        dateOnly
                      />
                    </template>
                    <template #note="{ value }">
                      <TMessageTextarea
                        :value="value"
                        :editable="deliveryEditable"
                        @change="updateDelivery('note', $event)"
                      />
                    </template>
                  </TTableAsForm>
                </CCol>
                <CCol col="5">
                  <div class="scroll-x inline-flex text-nowrap">
                    <TInputFile
                      :value.sync="fileRecords"
                      theme=""
                      :options="{ linkable: true }"
                      @selected="filesSelected($event)"
                      @deleted="fileDeleted($event)"
                    />
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol col="12">
          <!-- Suggestion sku -->
          <CCard>
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage content="Suggestion SKUs" bold color="muted" />
                <TSpinner :loading="boxes_available.loading || submitLoading" />
              </div>
              <TButtonRefresh
                class="ml-auto"
                @click="fetchBoxesAvailable"
                :options="{
                  disabled: boxes_available.loading,
                }"
              />
            </CCardHeader>
            <CCardBody>
              <SSelectCustomer
                noCustomLabel
                label="Select customer"
                :value.sync="customer_id"
                @change="onChangeCustomer"
              />
              <div class="scroll-x">
                <TMessageNoData
                  content="No SKU"
                  v-if="
                    !boxes_available.data.length &&
                    customer_id &&
                    !boxes_available.loading
                  "
                />
                <div class="d-inline-flex" v-if="customer_id">
                  <SCardSku
                    v-for="owner in boxes_available.data"
                    :key="owner.id"
                    :box="owner.box"
                    class="my-3 mx-3"
                    style="width: 185px"
                    :focusQuantity="owner.available_quantity"
                    @click.native="getBoxToList(owner)"
                    focusQuantityColor="secondary"
                    focusQuantityDescription="Available quantity"
                    mainQuantityDescription="Total number of boxes"
                    :showWeight="false"
                    :showSfa="false"
                    :class="
                      ownersSelected.some((x) => x.id == owner.id)
                        ? 'border-success'
                        : 'border-light'
                    "
                  >
                    <template #append-content>
                      <div class="d-flex flex-column align-items-center">
                        <div
                          class="d-flex justify-content-center flex-wrap"
                          v-if="owner.render_order_ids.length"
                        >
                          <CBadge
                            v-for="order_id in owner.render_order_ids"
                            color="primary"
                            class="h6 mx-1"
                            :key="order_id"
                          >
                            {{ order_id }}
                          </CBadge>
                        </div>
                      </div>
                    </template>
                    <template #append-actions>
                      <TInputCheckbox
                        :checked="ownersSelected.some((x) => x.id == owner.id)"
                        @change="getBoxToList(owner)"
                      />
                    </template>
                    <template #main-quantity>
                      <TMessage
                        v-if="owner.quantity < 0"
                        content="Combine box"
                      />
                      <TMessageNumber v-else :value="owner.quantity" />
                    </template>
                  </SCardSku>
                </div>
              </div>
            </CCardBody>
            <CCardFooter class="d-flex flex-wrap">
              <CLink
                class="ml-2 d-flex align-items-center"
                @click="onSelectAll"
                v-if="ownersSelected.length < boxes_available.data.length"
                :disabled="submitLoading"
              >
                <CIcon name="cil-check" class="mr-1" />
                <TMessage content="Select all" bold />
              </CLink>
              <CLink
                class="ml-2 d-flex text-danger align-items-center"
                @click="ownersSelected = []"
                v-if="ownersSelected.length"
                :disabled="submitLoading"
              >
                <CIcon name="cil-x-circle" class="mr-1" />
                <TMessage content="Deselect all" bold color="text-danger" />
              </CLink>
              <TButton
                class="ml-auto"
                color="success"
                :messageOptions="{ bold: true, noTranslate: true }"
                icon="cil-plus"
                :options="{
                  disabled: !ownersSelected.length || submitLoading,
                }"
                @click="submit"
              >
                <template #content>
                  <TMessage content="Add" bold class="ml-2">
                    <template #suffix>
                      <span v-if="ownersSelected.length">
                        {{
                          `(${_.sumBy(ownersSelected, "quantity")} ${$t(
                            "boxes"
                          )})`
                        }}
                      </span>
                    </template>
                  </TMessage>
                </template>
              </TButton>
            </CCardFooter>
          </CCard>

          <!-- Form add sku manually & table goods_delivery_boxes -->
          <CCard>
            <CCardHeader class="d-flex">
              <div class="d-flex align-items-center">
                <TMessage content="SKU list" bold />
                <CBadge
                  color="danger"
                  class="mx-1"
                  v-if="goods_delivery_boxes_total_box > 0"
                >
                  {{ goods_delivery_boxes_total_box }}
                </CBadge>
                <TSpinner :loading="detail_loading || submitLoading" />
              </div>
              <div class="ml-auto d-flex">
                <CDropdown
                  color="primary"
                  class="mr-2"
                  v-if="
                    goods_delivery_selected.status_id == 'waiting_shipment' &&
                    !isCleanUpWarehouse
                  "
                >
                  <template #toggler-content>
                    <CIcon name="cid-cloud-upload" class="mr-2 m-0" />
                    <span>{{ $t("Import excel") }}</span>
                  </template>
                  <CDropdownItem @click.native="showImportData = true">
                    <TMessage content="Create new" />
                  </CDropdownItem>
                  <CDropdownItem @click.native="showUpdateShippingCode = true">
                    <TMessage content="Shipping code" />
                  </CDropdownItem>
                </CDropdown>
                <TButtonRefresh
                  @click="fetchGoodsDeliveryBoxes"
                  :options="{
                    disabled: detail_loading,
                  }"
                  class="my-auto"
                />
              </div>
              <ImportExcelGoodsDelivery
                :show.sync="showImportData"
                :goods_delivery_id="goods_delivery_selected.id"
                @updated="fetchGoodsDeliveryBoxes"
              />
              <ImportExcelGoodsDelivery
                mode="update-shipping-code"
                :show.sync="showUpdateShippingCode"
                :goods_delivery_id="goods_delivery_selected.id"
                @updated="fetchGoodsDeliveryBoxes"
              />
            </CCardHeader>
            <CCardBody>
              <!-- Form add sku manually -->
              <div class="mb-3">
                <FormAddGoodDeliveryBox
                  ref="formAddGoodDeliveryBox"
                  @submit="onAddGoodDeliveryBoxManually"
                  :loading="submitLoading"
                />
              </div>

              <!-- Table goods_delivery_boxes -->
              <TTableAdvance
                :items="goods_delivery_boxes"
                :fields="fields"
                @click-clear-filter="clearFilter"
                store="warehouse.goods_delivery_boxes"
                resource=""
                @row-removed="fetchBoxesAvailable()"
                removable
              >
                <template #id="{ item }">
                  <td>
                    <TMessage :content="item.id" noTranslate />
                  </td>
                </template>
                <template #quantity="{ item }">
                  <td>
                    <TMessageNumber
                      :value="item.quantity"
                      editable
                      @change="updateQuantity(item.id, $event)"
                    />
                  </td>
                </template>
                <template #name="{ item }">
                  <td>
                    <TMessage :content="item.name" noTranslate />
                  </td>
                </template>
                <template #customer="{ item }">
                  <td>
                    <TMessage
                      :content="
                        item.customer ? item.customer.name : item.customer_id
                      "
                      bold
                      noTranslate
                    />
                  </td>
                </template>
                <template #shipping_code="{ item }">
                  <td>
                    <TMessageText
                      :value="item.shipping_code"
                      editable
                      @change="updateShippingCode(item, $event)"
                    />
                  </td>
                </template>
                <template #order_id="{ item }">
                  <td>
                    <TMessageText v-if="item.order_id" :value="item.order_id" />
                    <TMessageNotFound v-else />
                  </td>
                </template>
                <template #box_id-filter>
                  <TInputText
                    placeholder="SKU Id"
                    :value.sync="filter.box_id"
                    @update:value="filterChange"
                  />
                </template>
                <template #customer-filter>
                  <SSelectCustomer
                    :value.sync="filter.customer_id"
                    @change="filterChange"
                    noCustomLabel
                    prependAll
                  />
                </template>
                <template #order_id-filter>
                  <TInputText
                    placeholder="Order Id"
                    :value.sync="filter.order_id"
                    @update:value="filterChange"
                  />
                </template>
                <template #shipping_code-filter>
                  <TInputText
                    placeholder="Shipping code"
                    :value.sync="filter.shipping_code"
                    @update:value="filterChange"
                  />
                </template>
              </TTableAdvance>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CCard v-else>
        <CCardHeader>
          <TMessage content="Create new goods delivery" />
        </CCardHeader>
        <CCardBody>
          <CRow class="mb-3">
            <CCol md="6">
              <SSelectDeliveryPartner
                label="Select partner"
                noFetchStore
                :value.sync="input_goods_delivery.partner_id"
              />
            </CCol>
            <CCol md="6">
              <SSelectPlaceOfDelivery
                label="Address"
                :value.sync="input_goods_delivery.place_of_delivery_id"
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <TButton
            content="Create goods delivery"
            icon="cilPlus"
            class="float-right"
            @click="createGoodsDelivery"
            :disabled="goods_deliveries_creating"
          />
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import FilterForGoodsDelivery from "../../components/FilterForGoodsDelivery.vue";
import ImportExcelGoodsDelivery from "../../components/ImportExcelGoodsDelivery.vue";
import FormAddGoodDeliveryBox from "../../components/FormAddGoodDeliveryBox.vue";
import filterable from "../../mixins/filterable";

export default {
  components: {
    FilterForGoodsDelivery,
    ImportExcelGoodsDelivery,
    FormAddGoodDeliveryBox,
  },
  mixins: [filterable],
  props: {
    isCleanUpWarehouse: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      input_goods_delivery: {},
      goodsDelivertFields: [
        { key: "place_of_delivery", label: "To" },
        { key: "consignee", label: "Consignee" },
        { key: "delivery_partner", label: "Partner" },
        { key: "shipping_cost", label: "Shipping fee" },
        { key: "created_at", label: "Created at" },
        { key: "exported_date", label: "Exported date" },
        { key: "note", label: "Note" },
      ],
      customer_id: "",
      customer_name: "",
      fields: [
        { key: "_", _style: "width: 20px" },
        {
          key: "box_id",
          label: "SKU Id",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "quantity",
          label: "Quantity",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "shipping_code",
          label: "Shipping code",
          _classes: "",
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "customer",
          label: "Customer",
          _classes: "",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "order_id",
          label: "Order Id",
          _classes: "",
          _style: "width: 150px; min-width: 150px",
        },
      ],
      filter: {},
      showImportData: false,
      showUpdateShippingCode: false,
      boxes_available: {
        loading: false,
        data: [],
      },
      ownersSelected: [],
      submitLoading: false,
      fileRecords: [],
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.goods_deliveries.fetch.if-first-time");
    } else
      this.$store.dispatch(
        "warehouse.goods_deliveries.apply-query",
        this.query
      );
  },
  watch: {
    goods_delivery_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.ownersSelected = [];
          this.$store.dispatch("warehouse.goods_deliveries.detail.fetch", id);
          this.fetchGoodsDeliveryBoxes();
        }
      },
    },
    customer_id: {
      immediate: false,
      handler(id) {
        this.ownersSelected = [];
        if (id) this.fetchBoxesAvailable();
      },
    },
    goods_delivery_selected: {
      immediate: true,
      handler(rs) {
        this.fileRecords = rs.attachments?.map((attachment) => {
          return {
            id: attachment?.id,
            name: attachment.path_file,
            size: 1,
            type: attachment?.info_file?.mime_type || "image",
            ext: attachment?.info_file?.extension || "jpg",
            url:
              process.env.VUE_APP_WAREHOUSE_SERVICE_HOST +
              "/files/" +
              attachment.path_file,
          };
        });
      },
    },
  },
  computed: {
    goods_delivery_id_selected() {
      return this.$route.query.goods_delivery_id;
    },
    goods_delivery_selected() {
      return this.$store.getters["warehouse.goods_deliveries.detail"];
    },
    goods_deliveries() {
      return this.$store.getters["warehouse.goods_deliveries.list"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.goods_deliveries.fetching"];
    },
    goods_delivery_boxes() {
      return this.$store.getters["warehouse.goods_delivery_boxes.list"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.goods_delivery_boxes.loading"];
    },
    goods_deliveries_creating() {
      return this.$store.getters["warehouse.goods_deliveries.creating"];
    },
    goods_delivery_loading() {
      return this.$store.getters["warehouse.goods_deliveries.detail.loading"];
    },
    export_loading() {
      return this.$store.getters["helper.exports.loading"];
    },
    deliveryEditable() {
      return !this.detail_loading;
    },
    goods_delivery_boxes_total_box() {
      return (this.goods_delivery_selected.pivot_boxes || []).reduce(
        (p, c) => p + c.quantity,
        0
      );
    },
  },
  methods: {
    onChangeCustomer({ name }) {
      this.customer_name = name;
    },
    onSelectAll() {
      this.boxes_available.data.forEach((owner) => {
        this.getBoxToList(owner, true);
      });
    },
    getBoxToList(owner, force_add = false) {
      const existIndex = this.ownersSelected.findIndex((x) => x.id == owner.id);
      if (existIndex == -1)
        this.ownersSelected.push({
          id: owner.id,
          box_id: owner.box_id,
          order_id: owner?.order_id,
          quantity: owner.available_quantity,
        });
      else if (!force_add) this.ownersSelected.splice(existIndex, 1);
    },
    fetchGoodsDeliveryBoxes() {
      this.$store.dispatch("warehouse.goods_delivery_boxes.apply-query", {
        "filter[goods_delivery_id]": this.goods_delivery_id_selected,
      });
    },
    refetchGoodsDelivery() {
      this.$store.dispatch("warehouse.goods_deliveries.detail.refresh");
    },
    submit() {
      if (!this.ownersSelected?.length) return;

      this.submitLoading = true;

      let goods_delivery_boxs = this.ownersSelected.map((owner) => ({
        customer_id: this.customer_name,
        ...this.lodash.pick(owner, ["box_id", "quantity", "order_id"]),
      }));

      return this.$store
        .dispatch("warehouse.goods_delivery_boxes.create_multiple", {
          goods_delivery_id: this.goods_delivery_id_selected,
          goods_delivery_boxs,
        })
        .then(() => {
          this.ownersSelected = [];

          this.fetchGoodsDeliveryBoxes();
          this.fetchBoxesAvailable();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    createGoodsDelivery() {
      this.$store
        .dispatch("warehouse.goods_deliveries.create", {
          shipping_cost: 0,
          ...this.input_goods_delivery,
        })
        .then((goods_delivery) => {
          this.input_goods_delivery = {};
          this.$router.push({
            query: { goods_delivery_id: goods_delivery.id },
          });
        });
    },
    fetchBoxesAvailable() {
      if (!this.customer_id) return;

      this.$tomoni.warehouse.owning_boxes_available
        .all({
          customer_id: this.customer_id,
          type: "goods_delivery",
        })
        .then(({ data }) => {
          let { boxes, boxes_parent } = data;

          let r = [];

          boxes.forEach((box) => {
            box.owners.forEach((owner) => {
              r.push({
                id: owner.id,
                order_id: owner.order_id,
                box_id: owner.box_id,
                quantity: owner.quantity_of_owner_in_area,
                available_quantity:
                  owner.quantity_of_owner_in_area -
                  owner.quantity_in_goods_deliveries -
                  owner.quantity_in_lading_bills,
                box: owner.box,
                render_order_ids: [owner.order_id],
              });
            });
          });

          boxes_parent.forEach((parent) => {
            r.push({
              id: parent.id,
              box_id: parent.id,
              quantity: parent.duplicate,
              available_quantity: parent.quantity_available_for_goods_delivery,
              box: this.lodash.omit(parent, ["owners"]),
              render_order_ids: parent.owners.map((i) => i.order_id),
            });
          });

          this.boxes_available.data = r;
        })
        .finally(() => {
          this.boxes_available.loading = false;
        });
    },
    updateQuantity(goods_delivery_box_id, quantity) {
      this.$store.dispatch("warehouse.goods_delivery_boxes.update", {
        id: goods_delivery_box_id,
        attributes: { quantity },
      });
    },
    updateDelivery(field, value) {
      this.$store
        .dispatch("warehouse.goods_deliveries.detail.update", {
          [field]: value,
        })
        .then(() => {
          this.refetchGoodsDelivery();
        });
    },
    updateShippingCode(gdb, value) {
      this.$store.dispatch("warehouse.goods_delivery_boxes.update", {
        id: gdb.id,
        attributes: { shipping_code: value },
      });
    },
    removeGD() {
      this.$store
        .dispatch("warehouse.goods_deliveries.detail.delete")
        .then(() => {
          this.$router.push(`/warehouse/internal/goods-deliveries`);
        });
    },
    exportExcel() {
      this.$store
        .dispatch("helper.exports.export-goods-delivery", {
          goods_delivery_id: this.goods_delivery_id_selected,
        })
        .then((data) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          const customer = this.goods_delivery_selected.customer.name
            ? this.goods_delivery_selected.customer.name
            : this.goods_delivery_selected.customer.id;
          link.download = `Goods delivery ${customer}-${this.goods_delivery_id_selected}.xlsx`;
          link.click();
        });
    },
    filterChange() {
      let defaultFilter = {
        "filter[goods_delivery_id]": this.goods_delivery_id_selected,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });
      this.$store.dispatch(
        "warehouse.goods_delivery_boxes.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    importData(data) {
      this.$tomoni.helper.converts.importGoodsDelivery({
        goods_delivery_boxs: data,
      });
    },
    onAddGoodDeliveryBoxManually(values) {
      this.submitLoading = true;

      return this.$store
        .dispatch("warehouse.goods_delivery_boxes.create", {
          goods_delivery_id: this.goods_delivery_id_selected,
          ...this.lodash.pickBy(values),
          allow_without_order_id: this.isCleanUpWarehouse ? "1" : "0",
        })
        .then(() => {
          this.fetchGoodsDeliveryBoxes();
          this.fetchBoxesAvailable();
          this.$refs.formAddGoodDeliveryBox.resetForm();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    fileDeleted: function (fileRecord) {
      this.$store.dispatch(
        "warehouse.goods_deliveries.detail.detach-attachment",
        {
          attachment_id: fileRecord.id,
        }
      );
    },
    filesSelected: function (files) {
      let promises = [];
      files.forEach((i) => {
        promises.push(
          this.$store.dispatch(
            "warehouse.goods_deliveries.detail.attach-attachment",
            {
              file: i.file,
            }
          )
        );
      });
      Promise.all(promises).then(() => {
        this.refetchGoodsDelivery();
      });
    },
  },
};
</script>
