<template>
  <CRow>
    <CCol col="9">
      <CRow>
        <CCol col="4">
          <TInputText label="SKU" :value.sync="form_items.box_id" />
        </CCol>

        <CCol col="4">
          <SSelectOrderOwningBox
            label="Order"
            :value.sync="form_items.order_id"
            @change="onChangeOrder"
          />
        </CCol>

        <CCol col="4" >
          <TInputNumber label="Quantity" :value.sync="form_items.quantity" />
        </CCol>
        <CCol col="4" class="mt-3">
          <TInputText
            label="Shipping code"
            :value.sync="form_items.shipping_code"
          />
        </CCol>

        <CCol col="4" class="mt-3">
          <SSelectCustomer
            label="Customer"
            :value.sync="form_items.customer_id"
            :prependAll="false"
            :prepend="{ id: '', name: $t('Select') }"
          />
        </CCol>
      </CRow>
    </CCol>

    <CCol col="3" class="d-flex align-items-end justify-content-end">
      <TButtonClear
        v-if="!isDisabledButtonAdd || !loading"
        @click="resetForm"
        class="mr-2"
        size=""
        variant="outline"
      />
      <TButtonAdd
        @click="submitForm"
        :options="{ content: 'Add' }"
        variant=""
        size=""
        v-if="!loading"
      />
      <div v-else class="d-flex align-items-center" style="height: 33px">
        <TSpinner loading />
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form_items: {
        quantity: 1,
        order_id: "",
      },
    };
  },
  watch: {
    "form_items.box_id": {
      immediate: true,
      handler(v) {
        if (v) {
          this.$store
            .dispatch("warehouse.order_owning_boxes.apply-query", {
              "filter[box_id]": v,
            })
            .then(({ data }) => {
              if (data.length == 1)
                this.form_items.order_id = data[0]?.order_id;
              else {
                this.form_items.order_id = "";
                this.form_items.customer_id = "";
              }
            });
        }
      },
    },
  },
  computed: {
    isDisabledButtonAdd() {
      if (this.form_items.quantity < 1) return true;
      if (!this.form_items.box_id || !this.form_items.order_id) return true;

      return false;
    },
  },
  methods: {
    submitForm() {
      this.$emit("submit", this.form_items);
    },
    onChangeOrder(order) {
      this.$set(this.form_items, "customer_id", order.user_id);
    },
    resetForm() {
      this.form_items = { quantity: 1, order_id: "" };
    },
  },
};
</script>
